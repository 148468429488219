<template>
  <div>
    <!--begin: Head -->
    <div
      class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <div class="kt-user-card__avatar">
        <img
          class="kt-hidden"
          alt="Pic"
          src="@/assets/media/users/default.jpg"
        />
        <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
        <span
          class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success"
          v-if="currentUser.name"
          >{{ currentUser.name.substring(0, 1).toUpperCase() }}</span
        >
      </div>
      <div class="kt-user-card__name">{{ currentUser.name }}</div>
      <div class="kt-user-card__badge" hidden>
        <span class="btn btn-success btn-sm btn-bold btn-font-md"
          >23 messages</span
        >
      </div>
    </div>
    <!--end: Head -->
    <!--begin: Navigation -->
    <div class="kt-notification">
      <!--      <a href="#" class="kt-notification__item">-->
      <!--        <div class="kt-notification__item-icon">-->
      <!--          <i class="flaticon2-calendar-3 kt-font-success"></i>-->
      <!--        </div>-->
      <!--        <div class="kt-notification__item-details">-->
      <!--          <div class="kt-notification__item-title kt-font-bold">My Profile</div>-->
      <!--          <div class="kt-notification__item-time">-->
      <!--            Account settings and more-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </a>-->
      <!--      <a href="#" class="kt-notification__item">-->
      <!--        <div class="kt-notification__item-icon">-->
      <!--          <i class="flaticon2-cardiogram kt-font-warning"></i>-->
      <!--        </div>-->
      <!--        <div class="kt-notification__item-details">-->
      <!--          <div class="kt-notification__item-title kt-font-bold">Billing</div>-->
      <!--          <div class="kt-notification__item-time">-->
      <!--            billing & statements-->
      <!--            <span-->
      <!--              class="kt-badge kt-badge&#45;&#45;danger kt-badge&#45;&#45;inline kt-badge&#45;&#45;pill kt-badge&#45;&#45;rounded"-->
      <!--              >2 pending</span-->
      <!--            >-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </a>-->
      <div class="kt-notification__custom kt-space-between">
        <a
          href="#"
          v-on:click="onLogout()"
          class="btn btn-label btn-label-brand btn-sm btn-bold"
          >{{ t("GLOBAL.SIGNOUT") }}</a
        >
      </div>
    </div>
    <!--end: Navigation -->
  </div>
</template>

<script>
import { LOGOUT } from "@/store/auth.store";
import { mapGetters } from "vuex";

export default {
  name: "KTDropdownUser",
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "logout" }));
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
    backgroundImage() {
      return "/assets/media/misc/bg-1.jpg";
    }
  }
};
</script>
