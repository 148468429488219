<template>
  <div>
    <!-- begin::Sticky Toolbar -->
    <ul class="kt-sticky-toolbar" style="margin-top: 30px;">
      <li
        v-b-tooltip.hover.left="'Layout builder'"
        class="kt-sticky-toolbar__item kt-sticky-toolbar__item--brand"
      >
        <router-link href="#" :to="{ name: 'builder' }">
          <i class="flaticon2-gear"></i>
        </router-link>
      </li>
      <li
        v-b-tooltip.hover.left="'Documentation'"
        class="kt-sticky-toolbar__item kt-sticky-toolbar__item--warning"
      >
        <a href="https://keenthemes.com/metronic/?page=docs" target="_blank">
          <i class="flaticon2-telegram-logo"></i>
        </a>
      </li>
    </ul>
    <!-- end::Sticky Toolbar -->
  </div>
</template>

<script>
import KTOffcanvas from "@/assets/js/offcanvas.js";
import objectPath from "object-path";
import config from "@/config/layout.config.json";

export default {
  name: "KTStickyToolbar",
  props: {},
  components: {},
  computed: {},
  mounted() {
    new KTOffcanvas(this.$refs["kt_demo_panel"], {
      overlay: true,
      baseClass: "kt-demo-panel",
      closeBy: "kt_demo_panel_close",
      toggleBy: "kt_demo_panel_toggle"
    });
  },
  methods: {
    isActiveDemo(demo) {
      return demo === objectPath.get(config, "demo");
    }
  }
};
</script>
